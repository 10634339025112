import React, { useRef, useState } from 'react';
import Image from 'next/image';
import { FormikValues, useField, useFormikContext } from 'formik';
import SignatureCanvas from 'react-signature-canvas';
import { Typography } from 'components/atoms/typography';
import { ResetIcon, SignatureCanvasBox, SignatureCanvasClearBox, SignatureCanvasSignAgainLink } from './style';

interface TProps {
  width: number;
  height: number;
  showSignatureImg: boolean;
  fieldName?: string;
  email?: string;
  hideSignatureImg(): void;
}
const SignaturePad: React.FC<TProps> = ({
  width,
  height,
  showSignatureImg,
  fieldName = 'signaturePad',
  hideSignatureImg,
}: TProps) => {
  const [field] = useField(fieldName);
  const sigCanvas = useRef<SignatureCanvas | null>(null);
  const { setFieldValue, setFieldError, values } = useFormikContext();
  const [signatureImg, setSignatureImg] = useState((values as FormikValues)[fieldName]?.data);

  const saveSignature = async () => {
    const data = sigCanvas?.current?.getCanvas().toDataURL('image/png');
    const buffer = Buffer.from(data.replace(/^data:image\/\w+;base64,/, ''), 'base64');
    setSignatureImg(data);
    setFieldValue(fieldName, { file: buffer, data, type: 'image/png', name: `${fieldName}.png` });
  };

  const signAgain = () => {
    setSignatureImg('');
    hideSignatureImg();
    setFieldValue(fieldName, '');
    setTimeout(() => {
      setFieldError(fieldName, '');
    }, 1);
  };

  const clearSignature = () => {
    sigCanvas.current.clear();
    setSignatureImg('');
    if (signatureImg) {
      setFieldValue(fieldName, '');
      setTimeout(() => {
        setFieldError(fieldName, '');
      }, 0);
    }
  };

  return (
    <div data-testid={fieldName}>
      {!showSignatureImg ? (
        <>
          <SignatureCanvasBox>
            <SignatureCanvas
              {...field}
              ref={sigCanvas}
              onEnd={saveSignature}
              canvasProps={{
                width: `${width}`,
                height: height,
                className: `signaturePadCanvas`,
              }}
            />
          </SignatureCanvasBox>
          <SignatureCanvasClearBox onClick={clearSignature}>
            <ResetIcon />
            <Typography variant="body-small-bold" className="my-auto">
              Reset
            </Typography>
          </SignatureCanvasClearBox>
        </>
      ) : (
        <div>
          <Image
            src={signatureImg}
            alt={`User Signature`}
            height={height}
            width={width}
            loading="lazy"
            placeholder="empty"
            loader={(options) => options.src}
          />
          <SignatureCanvasSignAgainLink onClick={signAgain}>sign again</SignatureCanvasSignAgainLink>
        </div>
      )}
    </div>
  );
};

export default SignaturePad;
