import tw from 'tailwind-styled-components';

export const WidthFullContainer = tw.div`
    w-full
`;

export const CheckboxListContainer = tw.div`
    flex
    flex-wrap
    flex-col

    md:flex-row
    md:grid-rows-3
    md:gap-4
`;
export const CheckboxListItem = tw.div`
    flex 
    py-5
    border-b
    border-grey-200

    md:p-0
    md:border-none
`;

export const FormContent = tw.div`
    flex 
    w-full 
    flex-col 
    items-start 
    self-center 
    gap-20
    md:gap-[120px]
`;

export const RadioListContainer = tw.div`
    flex 
    flex-wrap 
    md:gap-5
    gap-6
`;
export const RadioListGridContainer = tw.div`
    w-full
    grid
    grid-cols-1 
    md:grid-cols-3 
    gap-6 
    md:gap-5
`;

export const FormInputContainer = tw.div`
    w-full
    flex 
    flex-wrap 
    flex-col 
    gap-4
    md:flex-row
    md:gap-10
`;

export const FormFooter = tw.div`
    flex
    w-full
    flex-col
    mb-14
    md:my-[120px]
`;
export const ORText = tw.div`
    w-full
    [&_h4]:inline-block
    [&_h4]:text-center
    [&_h4]:relative
    [&_h4]:pl-5
    [&_h4]:pr-5
    [&_h4]: bg-white
    [&_h4]:m-0
    md:[&_h4]:bottom-[44px]
    md:[&_h4]:left-[47%]
    [&_h4]:bg-white
    [&_hr]:clear-both
    [&_hr]:my-5
    [&_hr]:h-[2px]
    [&_hr]:bg-leaf
    sm:[&_h4]:bottom-[34px]
    sm:[&_h4]:left-[40%]
`;

export const GridCols3 = tw.div`
    grid
    grid-cols-1
    gap-4
    md:grid-cols-3
    lg:grid-cols-2
    md:gap-6
    w-full
`;

export const GridCols6 = tw.div`
    grid
    grid-cols-1
    gap-4
    md:grid-cols-6
    md:gap-6
    w-full
`;

export const Grid = tw.div`
    grid
    grid-cols-1
    md:grid-cols-3
    gap-4
    md:gap-6
`;

export const LegalContainer = tw.div`
    flex
    w-full 
    items-start 
    gap-2 
    rounded-md 
    border-[1px] 
    border-solid 
    border-grey-480 
    p-6
    md:p-5
    xl:p-6
    max-h-[708px]
    overflow-y-scroll
`;

export const CodeOfConductContent = tw.div`
    [&_p>b]:!leading-6
    [&_p>b]:text-xl
    [&_p>b]:!text-leaf
    [&_p]:!mb-2
    [&_p]:!flex
`;

export const PrivacyPracticesNoticeContent = tw.div`
    [&_p>b]:!leading-6
    [&_p>b]:text-2xl
    [&_p>b]:my-5
    [&_p>b]:flex
    [&_p]:!mb-2
`;

export const DeclarationName = tw.input`
    m-2 
    w-full
    h-16
    max-w-[325px]
    md:max-w-[330px]
    text-grey-400
    peer 
    bg-transparent 
    font-arial 
    font-normal 
    outline 
    outline-0 
    focus:outline-0 
    transition-all 
    !leading-[0.14px] 
    placeholder-shown:border 
    placeholder-shown:border-blue-gray-200 
    placeholder-shown:border-t-blue-gray-200 
    border focus:border 
    border-t-transparent 
    focus:border-t-transparent 
    text-sm px-3 
    py-2.5 
    rounded-[7px] 
    !pr-9 
    border-grey-480 
    focus:border-leaf
`;
