'use client';

import { forwardRef, useEffect, useState } from 'react';
import { CheckboxProps, Checkbox as MaterialCheckbox } from '@material-tailwind/react/components/Checkbox';
import CheckboxLabel from './checkbox-label';

export interface CheckboxLegalProps {
  onChange?: (obj: any, e: any) => any;
  label?: any;
  name?: string;
  isInnerHtml?: boolean;
  isRichText?: boolean;
}

const Checkbox = forwardRef(
  (
    {
      label = '',
      defaultChecked = false,
      onChange,
      className,
      containerProps,
      labelProps,
      name,
      isInnerHtml,
      isRichText,
      ...props
    }: CheckboxProps & CheckboxLegalProps,
    ref: any,
  ) => {
    const [isChecked, setIsChecked] = useState(defaultChecked);
    const onCheckboxChange = (e: any) => {
      const isChecked = e.target.checked;
      if (onChange) onChange({ name, value: isChecked }, e);
      setIsChecked(isChecked);
    };

    useEffect(() => {
      setIsChecked(defaultChecked);
    }, [defaultChecked]);

    return (
      <MaterialCheckbox
        ref={ref}
        className={className}
        containerProps={containerProps}
        labelProps={labelProps}
        defaultChecked={defaultChecked}
        crossOrigin=""
        onChange={onCheckboxChange}
        checked={isChecked}
        label={<CheckboxLabel label={label} isRichText={isRichText} isSelected={isChecked} isInnerHtml={isInnerHtml} />}
        {...props}
      />
    );
  },
);
Checkbox.displayName = 'Checkbox';

export default Checkbox;
