import React, { useState } from 'react';
import { Formik } from 'formik';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import CheckboxList from 'components/atoms/inputs/checkbox/checkbox-list-item';
import { RadioCheck } from 'components/atoms/inputs/radio/radio-card';
import { TextArea } from 'components/atoms/inputs/text-area';
import ReCaptcha from 'components/atoms/recaptcha/recaptcha';
import { Typography } from 'components/atoms/typography';
import HandleError from 'components/molecules/form-fields/handle-error';
import PhoneNumber from 'components/molecules/form-fields/phone-number';
import { SelectDispensaryDropdown, SelectStateDropdown } from 'components/molecules/form-fields/select';
import { EmailInput, FirstNameInput, LastNameInput } from 'components/molecules/form-fields/text-field';
import Loading from 'components/organisms/global/loader';
import { assistance, howDidYouFindUsItems } from 'helpers/constants/forms';
import { PatientRegValidationSchemaObj } from 'helpers/constants/validation-schema';
import { patientRegistrationFormSubmit } from 'helpers/forms/patient-registration-form-submit';
import useDispensary from 'helpers/hooks/use-dispensary';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import * as screenSizes from 'helpers/utils/screensizes';
import { FormBanner } from '../common/form-banner';
import { CenterContainer, ErrorContainer, MainContainer, RightContainer, UpperContainer } from '../common/style';
import { SuccessBanner } from '../common/success-banner';
import { Asterisk, FormHeader } from './patient-reg-header';
import {
  CheckboxListContainer,
  CheckboxListItem,
  FormContent,
  FormFooter,
  FormInputContainer,
  GridCols3,
  RadioListContainer,
  RadioListGridContainer,
  WidthFullContainer,
} from './style';

const PatientRegistrationForm = (props: any) => {
  const { currentDispensary } = useDispensary();
  const { stateCode } = currentDispensary?.state?.state || {};
  const { sidebarHeroBanner, step1Heading, step2Heading, step3Heading, successMessage } = props?.data || {};
  const initialValues = {
    help_you_with: '',
    description: '',
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    state: stateCode || '',
    dispensary: '',
    howDidYouFindUs: [],
    reCaptchaToken: '',
  };

  const [enableLoader, setLoader] = useState(false);
  const [showSuccessBanner, setShowsuccessBanner] = useState(false);
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const { dispensaries, states } = props?.stateList;

  const validateSteps = (values) => {
    return (
      !values.email ||
      !values.reCaptchaToken ||
      !values.phoneNumber ||
      !values.firstName ||
      !values.lastName ||
      !values.dispensary ||
      !values.help_you_with ||
      !values.state ||
      !values.howDidYouFindUs.length
    );
  };

  const handleCheckboxOnChange = ({ name, fieldValue, item, setFieldValue, event }) => {
    const selected = event.value;
    let data = fieldValue;
    if (selected) {
      data.push(item);
    } else {
      data = data.filter((value) => value != item);
    }
    setFieldValue(name, data);
  };

  const onSubmit = async (values) => {
    try {
      setLoader(true);
      const res = await patientRegistrationFormSubmit(values);
      if (res) setShowsuccessBanner(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const { image, mobileImage, overlayImage, subTitle, title } = sidebarHeroBanner || {};
  const bannerImage = isBiggerThanMobile ? image : mobileImage;
  const logo = isBiggerThanMobile ? overlayImage?.desktopImage : overlayImage?.mobileImage;
  return (
    <>
      <MainContainer>
        {enableLoader && <Loading showbackdrop={true} />}

        <FormBanner
          bannerImage={bannerImage}
          title={title}
          subTitle={subTitle}
          logo={logo}
          isBiggerThanMobile={isBiggerThanMobile}
          data-testid="patient_registration_form_banner"
        />
        <RightContainer>
          {!showSuccessBanner && (
            <Formik
              initialValues={initialValues}
              validationSchema={PatientRegValidationSchemaObj}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={onSubmit}
            >
              {(formikProps) => {
                const { setFieldValue, values, errors, touched, setTouched } = formikProps;
                return (
                  <>
                    <UpperContainer>
                      <CenterContainer $footer={true}>
                        <FormContent>
                          <WidthFullContainer>
                            <FormHeader
                              heading={step1Heading}
                              subHeading="This is a required step."
                              currentStep={1}
                              data-testid="patient_registration_header_1"
                            />
                            {errors.help_you_with && (
                              <ErrorContainer>
                                <HandleError
                                  name="help_you_with"
                                  error={`${errors.help_you_with}`}
                                  touched={touched.help_you_with ? true : false}
                                />
                              </ErrorContainer>
                            )}
                            <RadioListContainer data-testid="patient_registration_radiobuttonlist">
                              <RadioListGridContainer>
                                {assistance.map((item, idx) => (
                                  <RadioCheck
                                    name="help_you_with"
                                    value={values.help_you_with}
                                    checked={item.title === values.help_you_with}
                                    key={idx}
                                    label={item.title}
                                    onClick={() => setFieldValue('help_you_with', item.title)}
                                    CustomIcon={item.icon}
                                  />
                                ))}
                              </RadioListGridContainer>
                            </RadioListContainer>
                          </WidthFullContainer>
                          <div>
                            <FormHeader
                              heading={step2Heading}
                              subHeading="Required fields are marked with an asterisk."
                              currentStep={2}
                              data-testid="patient_registration_header_2"
                            />
                            <FormInputContainer data-testid="patient_registration_form_contact">
                              <GridCols3>
                                <SelectStateDropdown
                                  {...formikProps}
                                  required={true}
                                  items={states}
                                  label="State"
                                  data-testid="form_state_dropdown"
                                  onChange={(value) => {
                                    if (value !== values.state) {
                                      setFieldValue('dispensary', '');
                                      setTouched({ ...formikProps.touched, dispensary: false });
                                    }
                                    setFieldValue('state', value);
                                  }}
                                />
                                <SelectDispensaryDropdown
                                  {...formikProps}
                                  required={true}
                                  items={dispensaries[values.state]}
                                  label="Nearest RISE dispensary"
                                  data-testid="form_dispensary_dropdown"
                                />
                                <FirstNameInput {...formikProps} data-testid="form_input_firstname" required={true} />
                                <LastNameInput {...formikProps} data-testid="form_input_lastname" required={true} />
                                <PhoneNumber
                                  data-testid="form_input_phonenumber"
                                  label="Mobile Number"
                                  fieldName="phoneNumber"
                                  required={true}
                                  customOnChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                                />
                                <EmailInput {...formikProps} data-testid="form_input_email" required={true} />
                              </GridCols3>
                            </FormInputContainer>
                            <div>
                              <Typography
                                variant="h5"
                                data-testid="patient_registration_howDidYouFindUs_heading"
                                className="my-8 md:mt-10"
                              >
                                how did you find us? Check all that apply
                                <Asterisk>*</Asterisk>
                              </Typography>
                              {errors.howDidYouFindUs && (
                                <ErrorContainer>
                                  <HandleError
                                    name="howDidYouFindUs"
                                    error={`${errors.howDidYouFindUs}`}
                                    touched={touched.howDidYouFindUs ? true : false}
                                  />
                                </ErrorContainer>
                              )}

                              <CheckboxListContainer data-testid="patient_registration_checkbox">
                                {howDidYouFindUsItems.map((item) => (
                                  <CheckboxListItem key={item}>
                                    <CheckboxList
                                      label={item}
                                      name={item}
                                      onChange={(event) =>
                                        handleCheckboxOnChange({
                                          name: 'howDidYouFindUs',
                                          fieldValue: values.howDidYouFindUs,
                                          item,
                                          setFieldValue,
                                          event,
                                        })
                                      }
                                    />
                                  </CheckboxListItem>
                                ))}
                              </CheckboxListContainer>
                            </div>
                          </div>
                          <div className="w-full" data-testid="patient_registration_additionalNotes">
                            <FormHeader
                              heading={step3Heading}
                              currentStep={3}
                              data-testid="patient_registration_header_3"
                            />
                            <TextArea name="description" rows={10} data-testid="form_input_description" />
                          </div>
                        </FormContent>
                        <FormFooter>
                          <div className="mb-10 mt-8 md:my-0">
                            <ReCaptcha fieldName="reCaptchaToken" />
                          </div>
                          <div className="my-auto">
                            <CommonButton
                              data-testid="patient_registration_submit_button"
                              size="sm"
                              button-type="primary"
                              color="green"
                              className="mb-14 w-full md:mb-0 md:w-auto md:justify-end"
                              onClick={() => formikProps.submitForm()}
                              disabled={validateSteps(values)}
                            >
                              Submit
                            </CommonButton>
                          </div>
                        </FormFooter>
                      </CenterContainer>
                    </UpperContainer>
                  </>
                );
              }}
            </Formik>
          )}
          {showSuccessBanner && <SuccessBanner {...successMessage} />}
        </RightContainer>
      </MainContainer>
    </>
  );
};

export default PatientRegistrationForm;
