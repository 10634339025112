import React, { useState } from 'react';
import { Formik } from 'formik';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import CheckboxList from 'components/atoms/inputs/checkbox/checkbox-list-item';
import { RadioCheck } from 'components/atoms/inputs/radio/radio-card';
import { RadioBasic } from 'components/atoms/inputs/radio/radio-list-item';
import { TextArea } from 'components/atoms/inputs/text-area';
import InputTextField from 'components/atoms/inputs/text-field';
import ReCaptcha from 'components/atoms/recaptcha/recaptcha';
import { Typography } from 'components/atoms/typography';
import DOBField from 'components/molecules/form-fields/dob';
import HandleError from 'components/molecules/form-fields/handle-error';
import PhoneNumber from 'components/molecules/form-fields/phone-number';
import { SelectDispensaryDropdown } from 'components/molecules/form-fields/select';
import SignaturePad from 'components/molecules/form-fields/signature-pad';
import {
  EmailInput,
  FirstNameInput,
  FormikTextFieldInput,
  LastNameInput,
} from 'components/molecules/form-fields/text-field';
import Loading from 'components/organisms/global/loader';
import { howDidYouHearAboutUsItems, radioOptionYesNo } from 'helpers/constants/forms';
import { PatientRegNYValidationSchemaObj } from 'helpers/constants/validation-schema';
import { patientRegistrationNYFormSubmit } from 'helpers/forms/patient-registration-form-submit';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { s3UploadV2 } from 'helpers/s3-helper';
import * as screenSizes from 'helpers/utils/screensizes';
import { FormBanner } from '../common/form-banner';
import { CenterContainer, ErrorContainer, MainContainer, RightContainer, UpperContainer } from '../common/style';
import { SuccessBanner } from '../common/success-banner';
import CurrentMedication from './current-medication';
import { AuthorizationDeclaration, FormHeader, FormLabel, LegalText } from './patient-reg-header';
import { CodeOfConduct, PrivacyPracticesNotice } from './rich-text-content';
import {
  CheckboxListContainer,
  CheckboxListItem,
  FormContent,
  FormFooter,
  FormInputContainer,
  GridCols3,
  GridCols6,
  RadioListContainer,
  RadioListGridContainer,
} from './style';

const PatientRegistrationFormNY = (props: any) => {
  const { sidebarHeroBanner, successMessage, pdfCta, codeOfConductAgreement, privacyPracticesNotice } = props?.data;
  const initialValues = {
    firstName: '',
    lastName: '',
    medicalCardNumber: '',
    email: '',
    allowedComunication: [],
    state: 'NY',
    dispensary: '',
    address_1: '',
    address_2: '',
    city: '',
    zip: '',
    phoneNumber: '',
    homeNumber: '',
    occupation: '',
    dateOfBirth: '',
    financialHardships: '',
    senior: '',
    veteran: '',
    careGiverName: '',
    careGiverNumber: '',
    allergies: '',
    currentMedication: [{ medicationName: '', frequency: '', dosage: '' }],

    whyHasMedicalCannabisBeenRecommendedToYou: '',
    howLongHaveYouBeenUsingMedicalCannabis: '',
    doYouHaveAPreferredMethodOfConsumption: '',
    howDidYouFindUs: [],

    PatientsSignature: '',
    DatePrivacyPracticesNotice: '',

    SignatureCodeOfConductAgreement: '',
    DateCodeOfConductAgreement: '',

    declarationName: '',
    nameAuthorization: '',
    relationshipAuthorization: '',
    phoneAuthorization: '',
    addressAuthorization: '',
    informationToBeDisclosed: '',
    formOfDisclosure: [],
    authorizationShallBeEffectiveUntil: '',
    nameOfTheIndividualGivingThisAuthorization: '',
    dateOfBirthAuthorization: '',
    signatureOfTheIndividualGivingThisAuthorization: '',
    dateAuthorization: '',
    reCaptchaToken: '',
  };

  const [enableLoader, setLoader] = useState(false);
  const [showSuccessBanner, setShowsuccessBanner] = useState(false);
  const [showSignatureImg, setShowSignatureImg] = useState(false);
  const hideSignatureImg = () => setShowSignatureImg(false);
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const { dispensaries } = props?.stateList;

  const validateSteps = (values) => {
    return (
      !values.firstName ||
      !values.lastName ||
      !values.email ||
      !values.dispensary ||
      !values.phoneNumber ||
      !values.state ||
      !values.PatientsSignature ||
      !values.DatePrivacyPracticesNotice ||
      !values.SignatureCodeOfConductAgreement ||
      !values.DateCodeOfConductAgreement ||
      !values.nameOfTheIndividualGivingThisAuthorization ||
      !values.dateOfBirthAuthorization ||
      !values.signatureOfTheIndividualGivingThisAuthorization ||
      !values.dateAuthorization ||
      !values.reCaptchaToken
    );
  };

  const handleCheckboxOnChange = ({ name, fieldValue, item, setFieldValue, event }) => {
    const selected = event.value;
    let data = fieldValue;
    if (selected) {
      data.push(item);
    } else {
      data = data.filter((value) => value != item);
    }
    setFieldValue(name, data);
  };

  const onSubmit = async (values) => {
    try {
      setLoader(true);
      const signFiles = [];
      // @ts-ignore
      if (values.PatientsSignature) signFiles.push({ file: values.PatientsSignature.data });
      // @ts-ignore
      if (values.SignatureCodeOfConductAgreement) signFiles.push({ file: values.SignatureCodeOfConductAgreement.data });
      if (values.signatureOfTheIndividualGivingThisAuthorization.data)
        // @ts-ignore
        signFiles.push({ file: values.signatureOfTheIndividualGivingThisAuthorization.data });
      if (signFiles.length) {
        const signAttachments = await s3UploadV2(signFiles);
        values.user_attachments = signAttachments?.data;
        delete values.PatientsSignature;
        delete values.SignatureCodeOfConductAgreement;
        delete values.signatureOfTheIndividualGivingThisAuthorization;
      }
      const res = await patientRegistrationNYFormSubmit(values);
      if (res) setShowsuccessBanner(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const { image, mobileImage, overlayImage, subTitle, title } = sidebarHeroBanner;
  const bannerImage = isBiggerThanMobile ? image : mobileImage;
  const logo = isBiggerThanMobile ? overlayImage?.desktopImage : overlayImage?.mobileImage;
  return (
    <>
      <MainContainer>
        {enableLoader && <Loading showbackdrop={true} />}

        <FormBanner
          bannerImage={bannerImage}
          title={title}
          subTitle={subTitle}
          logo={logo}
          isBiggerThanMobile={isBiggerThanMobile}
          pdfCta={pdfCta}
          data-testid="patient_registration_form_banner"
        />
        <RightContainer>
          {!showSuccessBanner && (
            <Formik
              initialValues={initialValues}
              validationSchema={PatientRegNYValidationSchemaObj}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={onSubmit}
            >
              {(formikProps) => {
                const { setFieldValue, values, errors, touched, setFieldTouched } = formikProps;
                return (
                  <>
                    <UpperContainer>
                      <CenterContainer $footer={true}>
                        <FormContent>
                          {/* STEP 1 */}
                          <div className="flex w-full flex-col" data-testid="patient_registration_step_1">
                            <div>
                              <Typography
                                variant="body-small"
                                className={`mr-5 !text-grey-400 lg:mr-8`}
                                data-testid="form_header_step_1"
                              >
                                {`Step 1 of 5`}
                              </Typography>
                              <Typography variant="h3" data-testid="form_header_heading">
                                Personal info
                              </Typography>
                              <Typography variant="h5" data-testid="form_header_subheading" className="sm:my-6">
                                Thank You For Being Here!
                              </Typography>
                              <Typography
                                variant="body-small"
                                className={`mb-14 mr-5 !text-grey-400 lg:mr-8`}
                                data-testid="form_header_subheadingText"
                              >
                                We are honored you’ve chosen RISE as your medical cannabis provider. We look forward to
                                getting to know you. Please tell us a little about you and your history with medical
                                cannabis.
                              </Typography>
                            </div>
                            <FormInputContainer data-testid="patient_registration_form_contact">
                              <GridCols3>
                                <div className="md:col-span-1">
                                  <FirstNameInput {...formikProps} data-testid="form_input_firstname" required={true} />
                                </div>
                                <div className="md:col-span-1">
                                  <LastNameInput {...formikProps} data-testid="form_input_lastname" required={true} />
                                </div>
                                <div className="md:col-span-1 lg:col-span-2">
                                  <FormikTextFieldInput
                                    {...formikProps}
                                    fieldName="medicalCardNumber"
                                    label="Patient ID No./Medical Marijuana Card No."
                                    required={false}
                                    limit={25}
                                  />
                                </div>
                                <div className="md:col-span-2">
                                  <EmailInput {...formikProps} data-testid="form_input_email" required={true} />
                                </div>
                              </GridCols3>
                            </FormInputContainer>

                            <div className="mb-8">
                              <FormLabel text="Yes, I would like to receive information, community updates, and special offers. We promise we will not sell or share your contact information." />
                              <CheckboxListContainer data-testid="patient_registration_allowedEmailSms">
                                {['Email', 'Text'].map((item) => (
                                  <CheckboxListItem key={item}>
                                    <CheckboxList
                                      label={item}
                                      name={item}
                                      onChange={(event) =>
                                        handleCheckboxOnChange({
                                          name: 'allowedComunication',
                                          fieldValue: values.allowedComunication,
                                          item,
                                          setFieldValue,
                                          event,
                                        })
                                      }
                                    />
                                  </CheckboxListItem>
                                ))}
                              </CheckboxListContainer>
                            </div>

                            <FormInputContainer data-testid="patient_registration_form_personal_info">
                              <GridCols6>
                                <div className="md:col-span-2 lg:col-span-6">
                                  <SelectDispensaryDropdown
                                    {...formikProps}
                                    items={dispensaries[values.state]}
                                    label="Nearest RISE dispensary"
                                    data-testid="form_dispensary_dropdown"
                                    required={true}
                                  />
                                </div>
                                <div className="md:col-span-2 lg:col-span-3">
                                  <FormikTextFieldInput {...formikProps} fieldName="address_1" label="Address line 1" />
                                </div>
                                <div className="md:col-span-2 lg:col-span-3">
                                  <FormikTextFieldInput {...formikProps} fieldName="address_2" label="Address line 2" />
                                </div>
                                <div className="md:col-span-2">
                                  <FormikTextFieldInput {...formikProps} fieldName="city" label="City" />
                                </div>
                                <div className="md:col-span-2">
                                  <FormikTextFieldInput
                                    {...formikProps}
                                    fieldName="State"
                                    label="New York"
                                    disabled={true}
                                  />
                                </div>
                                <div className="md:col-span-2">
                                  <InputTextField
                                    message={errors?.zip as string}
                                    type="text"
                                    inputMode="numeric"
                                    error={Boolean(errors.zip && touched.zip)}
                                    label="Zip"
                                    ariaLabel="Zip"
                                    // required
                                    maxLength={5}
                                    value={values['zip']}
                                    onChange={(e) => {
                                      if (!isNaN(e.target.value)) {
                                        setFieldValue('zip', e.target.value);
                                      } else {
                                        setFieldValue('zip', '');
                                      }
                                    }}
                                    name="zip"
                                    onBlur={() => {
                                      setFieldTouched('zip', true);
                                    }}
                                  />
                                </div>
                                <div className="md:col-span-3">
                                  <PhoneNumber
                                    data-testid="form_input_phonenumber"
                                    label="Mobile Number"
                                    fieldName="phoneNumber"
                                    customOnChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                                    required={true}
                                  />
                                </div>
                                <div className="md:col-span-3">
                                  <PhoneNumber
                                    data-testid="form_input_phonenumber"
                                    label="Home Phone"
                                    fieldName="homeNumber"
                                    customOnChange={(e) => setFieldValue('homeNumber', e.target.value)}
                                    required={false}
                                  />
                                </div>
                                <div className="md:col-span-2 lg:col-span-3">
                                  <FormikTextFieldInput
                                    {...formikProps}
                                    fieldName="occupation"
                                    label="Occupation"
                                    required={false}
                                  />
                                </div>
                                <div className="md:col-span-3">
                                  <DOBField fieldName={'dateOfBirth'} required={false} />
                                </div>
                              </GridCols6>
                            </FormInputContainer>
                            <div className="mt-4">
                              <FormLabel text="Are You Facing Any Financial Hardships?" required={false} />
                              <RadioListContainer data-testid="patient_registration_financialHardships">
                                <RadioListGridContainer>
                                  {radioOptionYesNo.map((item, idx) => (
                                    <RadioCheck
                                      name="financialHardships"
                                      value={values.financialHardships}
                                      checked={item.title === values.financialHardships}
                                      key={idx}
                                      label={item.title}
                                      onClick={() => setFieldValue('financialHardships', item.title)}
                                      CustomIcon={item?.icon}
                                    />
                                  ))}
                                </RadioListGridContainer>
                              </RadioListContainer>
                            </div>
                            <div className="mt-4">
                              <FormLabel text="Are You A Senior" required={false} />
                              {errors.senior && (
                                <ErrorContainer>
                                  <HandleError
                                    name="senior"
                                    error={`${errors.senior}`}
                                    touched={touched.senior ? true : false}
                                  />
                                </ErrorContainer>
                              )}
                              <RadioListContainer data-testid="patient_registration_senior">
                                <RadioListGridContainer>
                                  {radioOptionYesNo.map((item, idx) => (
                                    <RadioCheck
                                      name="senior"
                                      value={values.senior}
                                      checked={item.title === values.senior}
                                      key={idx}
                                      label={item.title}
                                      onClick={() => setFieldValue('senior', item.title)}
                                      CustomIcon={item?.icon}
                                    />
                                  ))}
                                </RadioListGridContainer>
                              </RadioListContainer>
                            </div>
                            <div className="mt-4">
                              <FormLabel text="Are You A Veteran?" required={false} />
                              {errors.veteran && (
                                <ErrorContainer>
                                  <HandleError
                                    name="veteran"
                                    error={`${errors.veteran}`}
                                    touched={touched.veteran ? true : false}
                                  />
                                </ErrorContainer>
                              )}
                              <RadioListContainer data-testid="patient_registration_veteran">
                                <RadioListGridContainer>
                                  {radioOptionYesNo.map((item, idx) => (
                                    <RadioCheck
                                      name="veteran"
                                      value={values.veteran}
                                      checked={item.title === values.veteran}
                                      key={idx}
                                      label={item.title}
                                      onClick={() => setFieldValue('veteran', item.title)}
                                      CustomIcon={item?.icon}
                                    />
                                  ))}
                                </RadioListGridContainer>
                              </RadioListContainer>
                            </div>
                            <div className="mt-14">
                              <FormInputContainer data-testid="patient_registration_form_contact">
                                <GridCols3>
                                  <div className="col-span-1">
                                    <FormikTextFieldInput
                                      {...formikProps}
                                      fieldName="careGiverName"
                                      label="Caregiver Name (If Applicable)"
                                    />
                                  </div>
                                  <div className="col-span-1">
                                    <PhoneNumber
                                      data-testid="form_input_careGiverNumber"
                                      fieldName="careGiverNumber"
                                      label="Caregiver Phone No"
                                      customOnChange={(e) => setFieldValue('careGiverNumber', e.target.value)}
                                    />
                                  </div>
                                  <div className="md:col-span-1 lg:col-span-2">
                                    <FormikTextFieldInput
                                      {...formikProps}
                                      fieldName="allergies"
                                      label="Any Known Allergies?"
                                    />
                                  </div>
                                </GridCols3>
                              </FormInputContainer>
                            </div>
                            <div className="mt-4">
                              <CurrentMedication formikProps={formikProps} />
                            </div>
                          </div>

                          {/* STEP 2 */}
                          <div className="w-full" data-testid="patient_registration_step_2">
                            <FormHeader
                              heading={'Medical cannabis history'}
                              currentStep={2}
                              data-testid="patient_registration_header_2"
                              totalStep={5}
                            />
                            <div>
                              <FormLabel text="Why Has Medical Cannabis Been Recommended To You?" />
                              <FormikTextFieldInput
                                {...formikProps}
                                fieldName="whyHasMedicalCannabisBeenRecommendedToYou"
                                label="Tell us the reason"
                              />
                            </div>
                            <div className="mt-4 flex flex-col">
                              <FormLabel text="How Long Have You Been Using Medical Cannabis?" />
                              <div className="grid sm:grid-cols-1 md:grid-cols-3">
                                {['Never used medical cannabis', 'Less than 1 year', '1 – 3 Years', '3+ Years'].map(
                                  (item, idx) => (
                                    <RadioBasic
                                      key={idx + item}
                                      className="p-0"
                                      name="howLongHaveYouBeenUsingMedicalCannabis"
                                      value={values.howLongHaveYouBeenUsingMedicalCannabis}
                                      checked={item === values.howLongHaveYouBeenUsingMedicalCannabis}
                                      label={item}
                                      onClick={() => setFieldValue('howLongHaveYouBeenUsingMedicalCannabis', item)}
                                    />
                                  ),
                                )}
                              </div>
                            </div>
                            <div className="mt-4 flex flex-col">
                              <FormLabel text="Do You Have A Preferred Method Of Consumption?" />
                              <div className="grid sm:grid-cols-1 md:grid-cols-3">
                                {['Yes', 'No', 'Need Recommendation'].map((item, idx) => (
                                  <RadioBasic
                                    key={idx + item}
                                    className="p-0"
                                    name="doYouHaveAPreferredMethodOfConsumption"
                                    value={values.doYouHaveAPreferredMethodOfConsumption}
                                    checked={item === values.doYouHaveAPreferredMethodOfConsumption}
                                    label={item}
                                    onClick={() => setFieldValue('doYouHaveAPreferredMethodOfConsumption', item)}
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="mt-4 flex flex-col">
                              <FormLabel text="How did you find us? Check all that apply" required={false} />
                              {errors.howDidYouFindUs && (
                                <ErrorContainer>
                                  <HandleError
                                    name="howDidYouFindUs"
                                    error={`${errors.howDidYouFindUs}`}
                                    touched={touched.howDidYouFindUs ? true : false}
                                  />
                                </ErrorContainer>
                              )}
                              <CheckboxListContainer data-testid="patient_registration_checkbox">
                                {howDidYouHearAboutUsItems.map((item) => (
                                  <CheckboxListItem key={item}>
                                    <CheckboxList
                                      label={item}
                                      name={item}
                                      onChange={(event) =>
                                        handleCheckboxOnChange({
                                          name: 'howDidYouFindUs',
                                          fieldValue: values.howDidYouFindUs,
                                          item,
                                          setFieldValue,
                                          event,
                                        })
                                      }
                                    />
                                  </CheckboxListItem>
                                ))}
                              </CheckboxListContainer>
                            </div>
                          </div>
                          {/* STEP 3 */}
                          <div className="w-full" data-testid="patient_registration_step_3">
                            <FormHeader
                              heading={'Privacy practice notices'}
                              currentStep={3}
                              data-testid="patient_registration_header_3"
                              totalStep={5}
                            />
                            <FormLabel text="Why Has Medical Cannabis Been Recommended To You?" />
                            <PrivacyPracticesNotice data={privacyPracticesNotice} />

                            <FormLabel text="Patient’s Signature (Privacy Practices Notice)" required={true} />
                            {errors.PatientsSignature && (
                              <ErrorContainer>
                                <HandleError
                                  name="PatientsSignature"
                                  error={`${errors.PatientsSignature}`}
                                  touched={touched.PatientsSignature ? true : false}
                                />
                              </ErrorContainer>
                            )}
                            <div
                              className={`sm:mb-6  sm:min-h-[290px] md:mb-8 ${
                                isBiggerThanMobile ? 'w-[430px]' : 'min-w-[350px]'
                              }`}
                            >
                              <SignaturePad
                                fieldName="PatientsSignature"
                                showSignatureImg={showSignatureImg}
                                hideSignatureImg={hideSignatureImg}
                                width={isBiggerThanMobile ? 430 : 350}
                                height={176}
                              />
                              <div className="w-full sm:mt-6 md:mt-8">
                                <DOBField
                                  required={true}
                                  fieldName={'DatePrivacyPracticesNotice'}
                                  label="Date (Privacy Practices Notice)"
                                />
                              </div>
                            </div>
                          </div>
                          {/* STEP 4 */}
                          <div className="w-full" data-testid="patient_registration_step_4">
                            <FormHeader
                              heading={'Code of conduct agreement'}
                              currentStep={4}
                              data-testid="patient_registration_header_4"
                              totalStep={5}
                            />
                            <CodeOfConduct data={codeOfConductAgreement} />
                            <FormLabel text="Patient’s Signature (Code of Conduct Agreement)" required={true} />
                            {errors.SignatureCodeOfConductAgreement && (
                              <ErrorContainer>
                                <HandleError
                                  name="SignatureCodeOfConductAgreement"
                                  error={`${errors.SignatureCodeOfConductAgreement}`}
                                  touched={touched.SignatureCodeOfConductAgreement ? true : false}
                                />
                              </ErrorContainer>
                            )}
                            <div
                              className={`sm:mb-6  sm:min-h-[290px] md:mb-8 ${
                                isBiggerThanMobile ? 'w-[430px]' : 'min-w-[350px]'
                              }`}
                            >
                              <SignaturePad
                                fieldName="SignatureCodeOfConductAgreement"
                                showSignatureImg={showSignatureImg}
                                hideSignatureImg={hideSignatureImg}
                                width={isBiggerThanMobile ? 430 : 350}
                                height={176}
                              />
                              <div className="w-full sm:mt-6 md:mt-8">
                                <DOBField
                                  required={true}
                                  fieldName={'DateCodeOfConductAgreement'}
                                  label="Date (Code of Conduct Agreement)"
                                />
                              </div>
                            </div>
                          </div>
                          {/* STEP 5 */}
                          <div className="w-full" data-testid="patient_registration_step_5">
                            <FormHeader
                              heading={'Authorization'}
                              currentStep={5}
                              data-testid="patient_registration_header_4"
                              totalStep={5}
                            />
                            <AuthorizationDeclaration
                              value={values.declarationName}
                              handelOnchangeName={(val) => setFieldValue('declarationName', val)}
                            />
                            <div className="mt-8 w-full">
                              <FormikTextFieldInput
                                {...formikProps}
                                fieldName="nameAuthorization"
                                label="Name"
                                required={false}
                              />
                            </div>

                            <div className="mt-4 flex flex-col">
                              <FormLabel text="Relationship" />
                              <div className="grid sm:grid-cols-1 md:grid-cols-4">
                                {['Self', 'Caregiver'].map((item, idx) => (
                                  <RadioBasic
                                    key={idx + item}
                                    className="p-0"
                                    name="relationshipAuthorization"
                                    value={values.relationshipAuthorization}
                                    checked={item === values.relationshipAuthorization}
                                    label={item}
                                    onClick={() => setFieldValue('relationshipAuthorization', item)}
                                  />
                                ))}
                              </div>
                            </div>
                            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2">
                              <PhoneNumber
                                data-testid="form_input_phoneAuthorization"
                                label="Phone"
                                fieldName="phoneAuthorization"
                                customOnChange={(e) => setFieldValue('phoneAuthorization', e.target.value)}
                                required={false}
                              />
                              <FormikTextFieldInput {...formikProps} fieldName="addressAuthorization" label="Address" />
                            </div>
                            <div className="w-full" data-testid="patient_registration_additionalNotes">
                              <FormLabel text="Information To Be Disclosed (Upon The Request Of The Person Named Above)" />
                              <TextArea name="informationToBeDisclosed" rows={10} placeholder="Tell us the reason..." />
                            </div>
                            <div>
                              <FormLabel text="Form Of Disclosure (Select All That Apply)" />
                              <CheckboxListContainer data-testid="patient_registration_allowedEmailSms">
                                {['Verbal', 'Electronic Record', 'Hard Copy'].map((item) => (
                                  <CheckboxListItem key={item}>
                                    <CheckboxList
                                      label={item}
                                      name={item}
                                      onChange={(event) =>
                                        handleCheckboxOnChange({
                                          name: 'formOfDisclosure',
                                          fieldValue: values.formOfDisclosure,
                                          item,
                                          setFieldValue,
                                          event,
                                        })
                                      }
                                    />
                                  </CheckboxListItem>
                                ))}
                              </CheckboxListContainer>
                            </div>
                            <div className="mt-4 flex flex-col">
                              <FormLabel text="This Authorization Shall Be Effective Until (Select One)" />
                              <div className="grid sm:grid-cols-1 md:grid-cols-3">
                                {['All past, present, and future periods', 'Unless I revoke it', 'Selected Date'].map(
                                  (item, idx) => (
                                    <RadioBasic
                                      key={idx + item}
                                      className="p-0"
                                      name="authorizationShallBeEffectiveUntil"
                                      value={values.authorizationShallBeEffectiveUntil}
                                      checked={item === values.authorizationShallBeEffectiveUntil}
                                      label={item}
                                      onClick={() => setFieldValue('authorizationShallBeEffectiveUntil', item)}
                                    />
                                  ),
                                )}
                              </div>
                            </div>
                            <FormLabel text="Name Of The Individual Giving This Authorization" required={true} />
                            <div className="grid gap-6 sm:grid-cols-1 md:grid-cols-2">
                              <FormikTextFieldInput
                                {...formikProps}
                                fieldName="nameOfTheIndividualGivingThisAuthorization"
                                label="Name"
                                required={true}
                              />
                              <DOBField required={true} fieldName={'dateOfBirthAuthorization'} label="Date Of Birth" />
                            </div>
                            <FormLabel text="Signature Of The Individual Giving This Authorization" required={true} />
                            {errors.signatureOfTheIndividualGivingThisAuthorization && (
                              <ErrorContainer>
                                <HandleError
                                  name="signatureOfTheIndividualGivingThisAuthorization"
                                  error={`${errors.signatureOfTheIndividualGivingThisAuthorization}`}
                                  touched={touched.signatureOfTheIndividualGivingThisAuthorization ? true : false}
                                />
                              </ErrorContainer>
                            )}
                            <div
                              className={`sm:mb-6  sm:min-h-[290px] md:mb-8 ${
                                isBiggerThanMobile ? 'w-[430px]' : 'min-w-[350px]'
                              }`}
                            >
                              <SignaturePad
                                fieldName="signatureOfTheIndividualGivingThisAuthorization"
                                showSignatureImg={showSignatureImg}
                                hideSignatureImg={hideSignatureImg}
                                width={isBiggerThanMobile ? 430 : 350}
                                height={176}
                              />
                              <div className="w-full sm:mt-6 md:mt-8">
                                <DOBField
                                  required={true}
                                  fieldName={'dateAuthorization'}
                                  label="Date (Authorization)"
                                />
                              </div>
                            </div>
                          </div>
                        </FormContent>
                        <FormFooter data-testid="patient_registration_footer">
                          <div className="mb-3 flex flex-col justify-between md:flex-row">
                            <div className="sm:mb-10 sm:mt-8 md:my-0">
                              <ReCaptcha fieldName="reCaptchaToken" />
                            </div>
                            <div className="my-auto">
                              <CommonButton
                                data-testid="patient_registration_submit_button"
                                size="sm"
                                button-type="primary"
                                color="green"
                                className="md: mb-3 justify-end sm:w-full md:w-auto lg:mb-0"
                                onClick={() => formikProps.submitForm()}
                                disabled={validateSteps(values)}
                              >
                                Submit
                              </CommonButton>
                            </div>
                          </div>

                          <LegalText host={props.host} />
                        </FormFooter>
                      </CenterContainer>
                    </UpperContainer>
                  </>
                );
              }}
            </Formik>
          )}
          {showSuccessBanner && <SuccessBanner {...successMessage} />}
        </RightContainer>
      </MainContainer>
    </>
  );
};

export default PatientRegistrationFormNY;
