'use client';

import { useMemo } from 'react';
import { usePathname } from 'next/navigation';
import { useSelector } from 'react-redux';
import PatientRegistrationForm from 'components/organisms/forms/patient-registration/patient-registration';
import PatientRegistrationFormNY from 'components/organisms/forms/patient-registration/patient-registration-ny';
import PatientRegistrationFormVA from 'components/organisms/forms/patient-registration/patient-registration-va';
import { DISP_TAGS } from 'helpers/types/cookies';
import { RootState } from 'redux/store';

const Form = {
  NewYorkPatientRegisterationForm: 'new-york-patient-registration-form',
  PatientPreRegistrationForm: 'patient-preregistration-form',
  PatientRegistrationForm: 'patient-registration-form',
};

const PatientRegistrationPageTastics = (props: any) => {
  const host = props?.pageFolder?.host;
  let data = props?.data?.patientRegistrationData?.dataSource?.data?.pagePatientRegistrationCollection?.items[0];
  const dispensariesStateData: any = useSelector((state: RootState) => state?.dispensaryList?.dispensaryList) || [];
  const IsOhio = host.includes('oh.risecannabis');
  const stateList = useMemo(
    () =>
      dispensariesStateData?.reduce(
        (obj, { dispensaries, state }) => {
          const stateName = state?.state?.stateName || '';
          const stateCode = state?.state?.stateCode || '';
          const data = dispensaries?.reduce((obj, dispensary) => {
            if (!obj[stateCode]) obj[stateCode] = [];
            const label = dispensary?.dispensaryInformation?.title;
            if (
              !dispensary?.dispensaryTags?.includes(DISP_TAGS.COMING_SOON) &&
              !dispensary?.dispensaryTags?.includes(DISP_TAGS.LOUNGE)
            ) {
              obj[stateCode].push({ label, value: label });
            }
            return obj;
          }, {});

          if (!IsOhio && stateCode !== 'OH') {
            obj.dispensaries = { ...obj.dispensaries, ...data };
            obj.states.push({ label: stateName, value: stateCode });
          } else if (IsOhio && stateCode === 'OH') {
            obj.dispensaries = { ...obj.dispensaries, ...data };
            obj.states.push({ label: stateName, value: stateCode });
          }
          return obj;
        },
        { dispensaries: {}, states: [] },
      ),
    [dispensariesStateData, IsOhio],
  );

  const path = usePathname();
  const formType = path.includes(Form.NewYorkPatientRegisterationForm)
    ? Form.NewYorkPatientRegisterationForm
    : path.includes(Form.PatientPreRegistrationForm)
    ? Form.PatientPreRegistrationForm
    : Form.PatientRegistrationForm;

  switch (formType) {
    case Form.NewYorkPatientRegisterationForm:
      return <PatientRegistrationFormNY data={data} stateList={stateList} host={host} />;
    case Form.PatientPreRegistrationForm:
      return <PatientRegistrationFormVA data={data} stateList={stateList} host={host} />;
    case Form.PatientRegistrationForm:
    default:
      data = {
        ...data,
        step1Heading: 'I want assistance with',
        step2Heading: 'Contact information',
        step3Heading: 'Additional notes',
      };
      return <PatientRegistrationForm data={data} stateList={stateList} />;
  }
};

export default PatientRegistrationPageTastics;
