import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';
import { IRichTextData } from 'helpers/types';
import { CodeOfConductContent, LegalContainer, PrivacyPracticesNoticeContent } from './style';

interface IProps {
  data: IRichTextData;
}
export const PrivacyPracticesNotice = ({ data }: IProps) => {
  return (
    <LegalContainer data-testid="privacy_practices-notice">
      <ContentfulRichText
        data={data}
        renderNodeOptions={{
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <PrivacyPracticesNoticeContent>
              <Typography variant="body-small" className="!text-grey-400">
                {children}
              </Typography>
            </PrivacyPracticesNoticeContent>
          ),
        }}
      />
    </LegalContainer>
  );
};

export const CodeOfConduct = ({ data }: IProps) => {
  return (
    <LegalContainer data-testid="code_of_conduct">
      <ContentfulRichText
        data={data}
        renderNodeOptions={{
          [BLOCKS.PARAGRAPH]: (node, children) => (
            <CodeOfConductContent>
              <Typography variant="body-small" className="!text-grey-400">
                {children}
              </Typography>
            </CodeOfConductContent>
          ),
        }}
      />
    </LegalContainer>
  );
};
