import React, { useState } from 'react';
import { Select } from '@material-tailwind/react';
import { Formik, FormikValues } from 'formik';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import FilePicker from 'components/atoms/file-picker/file-picker';
import CheckboxList from 'components/atoms/inputs/checkbox/checkbox-list-item';
import { RadioCheck } from 'components/atoms/inputs/radio/radio-card';
import { TextArea } from 'components/atoms/inputs/text-area';
import InputTextField from 'components/atoms/inputs/text-field';
import ReCaptcha from 'components/atoms/recaptcha/recaptcha';
import { Typography } from 'components/atoms/typography';
import DOBField from 'components/molecules/form-fields/dob';
import HandleError from 'components/molecules/form-fields/handle-error';
import PhoneNumber from 'components/molecules/form-fields/phone-number';
import { SelectDispensaryDropdown } from 'components/molecules/form-fields/select';
import SignaturePad from 'components/molecules/form-fields/signature-pad';
import {
  EmailInput,
  FirstNameInput,
  FormikTextFieldInput,
  LastNameInput,
  MiddleNameInput,
} from 'components/molecules/form-fields/text-field';
import Loading from 'components/organisms/global/loader';
import { howDidYouFindUsItems, packagingPreferenceItems, radioOptionYesNo } from 'helpers/constants/forms';
import {
  PatientRegContactVAValidationSchemaObj,
  PatientRegVAValidationSchemaObj,
} from 'helpers/constants/validation-schema';
import { patientRegistrationVAFormSubmit } from 'helpers/forms/patient-registration-form-submit';
import useMediaQuery from 'helpers/hooks/useMediaQuery';
import { s3UploadV2 } from 'helpers/s3-helper';
import * as screenSizes from 'helpers/utils/screensizes';
import { FormBanner } from '../common/form-banner';
import { CenterContainer, ErrorContainer, MainContainer, RightContainer, UpperContainer } from '../common/style';
import { SuccessBanner } from '../common/success-banner';
import { FormHeader, FormLabel, LegalText } from './patient-reg-header';
import {
  CheckboxListContainer,
  CheckboxListItem,
  FormContent,
  FormFooter,
  FormInputContainer,
  GridCols3,
  GridCols6,
  ORText,
  RadioListContainer,
  RadioListGridContainer,
} from './style';

const PatientRegistrationFormVA = (props: any) => {
  const { sidebarHeroBanner, successMessage, vaFormTitle, vaFormDescription, pdfCta } = props?.data;
  const initialValues = {
    registeredWithCommonwealth: '',
    veteran: '',
    howDidYouFindUs: [],

    dispensary: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    dateOfBirth: '',
    address_1: '',
    address_2: '',
    city: '',
    state: 'VA',
    zip: '',
    patientId_MedicalCard: '',
    expirationDate: '',

    description: '',

    freeConsultation: '',
    allowedEmailSms: [],
    packagingPreference: '',

    virginia_ID: '',
    registrationCard: '',
    letterOfCertification: '',
    signatureVirginia: '',
    date: '',
    reCaptchaToken: '',
  };

  const [enableLoader, setLoader] = useState(false);
  const [showSuccessBanner, setShowsuccessBanner] = useState(false);
  const [showSignatureImg, setShowSignatureImg] = useState(false);
  const hideSignatureImg = () => setShowSignatureImg(false);
  const [isBiggerThanMobile] = useMediaQuery(screenSizes.mobile);
  const { dispensaries } = props?.stateList;

  const [validationSchema, setValidationSchema] = useState(PatientRegVAValidationSchemaObj);
  const validateSteps = (values) => {
    if (values.registeredWithCommonwealth !== 'No') {
      return (
        !values.veteran ||
        !values.howDidYouFindUs.length ||
        !values.dispensary ||
        !values.firstName ||
        !values.lastName ||
        !values.email ||
        !values.phoneNumber ||
        !values.city ||
        !values.state ||
        !values.zip ||
        !values.patientId_MedicalCard ||
        !values.expirationDate ||
        !values.freeConsultation ||
        !values.packagingPreference ||
        !values.virginia_ID ||
        !values.registrationCard ||
        !values.letterOfCertification ||
        !values.signatureVirginia ||
        !values.date ||
        !values.reCaptchaToken
      );
    } else {
      return (
        !values.email ||
        !values.reCaptchaToken ||
        !values.phoneNumber ||
        !values.firstName ||
        !values.lastName ||
        !values.dispensary ||
        !values.howDidYouFindUs ||
        !values.state
      );
    }
  };

  const handelCommonwealthChange = (setFieldValue, value, resetForm, prevValue) => {
    if (value !== 'No' && validationSchema !== PatientRegVAValidationSchemaObj) {
      setValidationSchema(PatientRegVAValidationSchemaObj);
    }
    if (value === 'No' && validationSchema !== PatientRegContactVAValidationSchemaObj) {
      // @ts-ignore
      setValidationSchema(PatientRegContactVAValidationSchemaObj);
    }
    if (prevValue !== '' || value === 'No') {
      resetForm();
      setFieldValue('howDidYouFindUs', []);
      setFieldValue('allowedEmailSms', []);
    }

    setFieldValue('registeredWithCommonwealth', value);
  };

  const handleCheckboxOnChange = ({ name, fieldValue, item, setFieldValue, event }) => {
    const selected = event.value;
    let data = fieldValue;
    if (selected) {
      data.push(item);
    } else {
      data = data.filter((value) => value != item);
    }
    setFieldValue(name, data);
  };

  const onSubmit = async (values: FormikValues) => {
    try {
      setLoader(true);

      if (values.registeredWithCommonwealth === 'Yes') {
        const files = [];
        // @ts-ignore
        if (values.virginia_ID) files.push(values.virginia_ID);
        // @ts-ignore
        if (values.registrationCard) files.push(values.registrationCard);
        // @ts-ignore
        if (values.letterOfCertification) files.push(values.letterOfCertification);
        // @ts-ignore
        if (values.signatureVirginia) files.push({ file: values?.signatureVirginia?.data });
        if (files.length) {
          const fileAttachments = await s3UploadV2(files);
          values.user_attachments = fileAttachments?.data;
          delete values.virginia_ID;
          delete values.registrationCard;
          delete values.letterOfCertification;
          delete values.signatureVirginia;
        }
      }
      const res = await patientRegistrationVAFormSubmit(values);
      if (res) setShowsuccessBanner(true);
      setLoader(false);
    } catch (error) {
      setLoader(false);
    }
  };
  const { image, mobileImage, overlayImage, subTitle, title } = sidebarHeroBanner;
  const bannerImage = isBiggerThanMobile ? image : mobileImage;
  const logo = isBiggerThanMobile ? overlayImage?.desktopImage : overlayImage?.mobileImage;
  return (
    <>
      <MainContainer>
        {enableLoader && <Loading showbackdrop={true} />}

        <FormBanner
          bannerImage={bannerImage}
          title={title}
          subTitle={subTitle}
          logo={logo}
          isBiggerThanMobile={isBiggerThanMobile}
          data-testid="patient_registration_form_banner"
        />
        <RightContainer>
          {!showSuccessBanner && (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              validateOnChange={true}
              validateOnBlur={true}
              onSubmit={onSubmit}
            >
              {(formikProps) => {
                const { setFieldValue, values, errors, touched, resetForm, setFieldTouched } = formikProps;
                return (
                  <>
                    <UpperContainer>
                      <CenterContainer $footer={true}>
                        <FormContent>
                          {/* STEP 1 */}
                          <div className="w-full" data-testid="patient_registration_step_1">
                            <FormHeader
                              heading={'Your history'}
                              subHeading="Required fields are marked with an asterisk."
                              currentStep={1}
                              data-testid="patient_registration_header_1"
                              totalStep={5}
                            />
                            <FormLabel
                              text="ARE YOU A REGISTERED PATIENT WITH THE COMMONWEALTH OF VIRGINIA?"
                              required={true}
                            />
                            {errors.registeredWithCommonwealth && (
                              <ErrorContainer>
                                <HandleError
                                  name="registeredWithCommonwealth"
                                  error={`${errors.registeredWithCommonwealth}`}
                                  touched={touched.registeredWithCommonwealth ? true : false}
                                />
                              </ErrorContainer>
                            )}
                            <RadioListContainer data-testid="patient_registration_registeredWithCommonwealth">
                              <RadioListGridContainer>
                                {radioOptionYesNo.map((item, idx) => (
                                  <RadioCheck
                                    name="registeredWithCommonwealth"
                                    value={values.registeredWithCommonwealth}
                                    checked={item.title === values.registeredWithCommonwealth}
                                    key={idx}
                                    label={item.title}
                                    onClick={() =>
                                      handelCommonwealthChange(
                                        setFieldValue,
                                        item.title,
                                        resetForm,
                                        values.registeredWithCommonwealth,
                                      )
                                    }
                                    CustomIcon={item?.icon}
                                  />
                                ))}
                              </RadioListGridContainer>
                            </RadioListContainer>
                            {values.registeredWithCommonwealth === 'No' && (
                              <FormContent>
                                <div className="flex flex-col">
                                  <FormLabel text={vaFormTitle} required={true} />
                                  <Typography
                                    variant="body-small"
                                    className={`my-8 mr-5 !text-grey-400 lg:mr-8`}
                                    data-testid="form_header_subheading"
                                  >
                                    {vaFormDescription}
                                  </Typography>
                                  <div className="flex">
                                    <CommonButton
                                      button-type="primary"
                                      color="green"
                                      as="a"
                                      href={pdfCta.longUrl}
                                      target="_blank"
                                      data-testid="patient_registration_Know_more"
                                    >
                                      {pdfCta.title}
                                    </CommonButton>
                                  </div>
                                </div>
                                <ORText>
                                  <hr />
                                  <Typography variant="h4">OR </Typography>
                                </ORText>

                                <div>
                                  <FormHeader
                                    heading="Contact information"
                                    subHeading="Required fields are marked with an asterisk."
                                    data-testid="patient_registration_header_2"
                                  />
                                  <FormInputContainer data-testid="patient_registration_form_contact">
                                    <GridCols3>
                                      <div className="xl:mb-4">
                                        <Select label="Virginia" disabled data-testid="form_state_dropdown">
                                          {[]}
                                        </Select>
                                      </div>
                                      <div className="xl:mb-4">
                                        {/* @ts-ignore */}
                                        <SelectDispensaryDropdown
                                          {...formikProps}
                                          items={dispensaries[values.state]}
                                          label="Nearest RISE dispensary"
                                          data-testid="form_dispensary_dropdown"
                                          required={true}
                                        />
                                      </div>
                                      <div className="xl:mb-4">
                                        <FirstNameInput
                                          {...formikProps}
                                          data-testid="form_input_firstname"
                                          required={true}
                                        />
                                      </div>
                                      <div className="xl:mb-4">
                                        <LastNameInput
                                          {...formikProps}
                                          data-testid="form_input_lastname"
                                          required={true}
                                        />
                                      </div>
                                      <div>
                                        <PhoneNumber
                                          data-testid="form_input_phonenumber"
                                          label="Phone"
                                          fieldName="phoneNumber"
                                          required={true}
                                          customOnChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                                        />
                                      </div>
                                      <div>
                                        <EmailInput {...formikProps} data-testid="form_input_email" required={true} />
                                      </div>
                                    </GridCols3>
                                  </FormInputContainer>
                                  <div>
                                    <FormLabel text="HOW DID YOU FIND US? CHECK ALL THAT APPLY" required={true} />
                                    {errors.howDidYouFindUs && (
                                      <ErrorContainer>
                                        <HandleError
                                          name="howDidYouFindUs"
                                          error={`${errors.howDidYouFindUs}`}
                                          touched={touched.howDidYouFindUs ? true : false}
                                        />
                                      </ErrorContainer>
                                    )}
                                    <CheckboxListContainer data-testid="patient_registration_checkbox">
                                      {howDidYouFindUsItems.map((item) => (
                                        <CheckboxListItem key={item}>
                                          <CheckboxList
                                            label={item}
                                            name={item}
                                            onChange={(event) =>
                                              handleCheckboxOnChange({
                                                name: 'howDidYouFindUs',
                                                fieldValue: values.howDidYouFindUs,
                                                item,
                                                setFieldValue,
                                                event,
                                              })
                                            }
                                          />
                                        </CheckboxListItem>
                                      ))}
                                    </CheckboxListContainer>
                                  </div>
                                </div>
                                <div className="w-full" data-testid="patient_registration_additionalNotes">
                                  <FormHeader heading="Additional notes" data-testid="patient_registration_header_3" />
                                  <TextArea name="description" rows={10} data-testid="form_input_description" />
                                </div>
                              </FormContent>
                            )}

                            {values.registeredWithCommonwealth !== 'No' && (
                              <>
                                <FormLabel text="ARE YOU A VETERAN?" required={true} />
                                {errors.veteran && (
                                  <ErrorContainer>
                                    <HandleError
                                      name="veteran"
                                      error={`${errors.veteran}`}
                                      touched={touched.veteran ? true : false}
                                    />
                                  </ErrorContainer>
                                )}
                                <RadioListContainer data-testid="patient_registration_veteran">
                                  <RadioListGridContainer>
                                    {radioOptionYesNo.map((item, idx) => (
                                      <RadioCheck
                                        name="veteran"
                                        value={values.veteran}
                                        checked={item.title === values.veteran}
                                        key={idx}
                                        label={item.title}
                                        onClick={() => setFieldValue('veteran', item.title)}
                                        CustomIcon={item?.icon}
                                      />
                                    ))}
                                  </RadioListGridContainer>
                                </RadioListContainer>
                                <FormLabel text="HOW DID YOU FIND US? CHECK ALL THAT APPLY" required={true} />
                                {errors.howDidYouFindUs && (
                                  <ErrorContainer>
                                    <HandleError
                                      name="howDidYouFindUs"
                                      error={`${errors.howDidYouFindUs}`}
                                      touched={touched.howDidYouFindUs ? true : false}
                                    />
                                  </ErrorContainer>
                                )}
                                <CheckboxListContainer data-testid="patient_registration_checkbox">
                                  {howDidYouFindUsItems.map((item) => (
                                    <CheckboxListItem key={item}>
                                      <CheckboxList
                                        label={item}
                                        name={item}
                                        onChange={(event) =>
                                          handleCheckboxOnChange({
                                            name: 'howDidYouFindUs',
                                            fieldValue: values.howDidYouFindUs,
                                            item,
                                            setFieldValue,
                                            event,
                                          })
                                        }
                                      />
                                    </CheckboxListItem>
                                  ))}
                                </CheckboxListContainer>
                              </>
                            )}
                          </div>

                          {values.registeredWithCommonwealth !== 'No' && (
                            <>
                              {/* STEP 2 */}
                              <div className="w-full" data-testid="patient_registration_step_2">
                                <FormHeader
                                  heading="New patient registration"
                                  subHeading="Required fields are marked with an asterisk."
                                  currentStep={2}
                                  data-testid="patient_registration_header_2"
                                  totalStep={5}
                                />
                                <FormInputContainer data-testid="patient_registration_form_contact">
                                  <GridCols6>
                                    <div className="md:col-span-2 xl:col-span-6 xl:mb-4">
                                      {/* @ts-ignore */}
                                      <SelectDispensaryDropdown
                                        {...formikProps}
                                        items={dispensaries[values.state]}
                                        label="Nearest RISE dispensary"
                                        data-testid="form_dispensary_dropdown"
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <FirstNameInput
                                        {...formikProps}
                                        data-testid="form_input_firstname"
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <MiddleNameInput
                                        {...formikProps}
                                        data-testid="form_input_middlename"
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <LastNameInput
                                        {...formikProps}
                                        data-testid="form_input_lastname"
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <EmailInput {...formikProps} data-testid="form_input_email" required={true} />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <PhoneNumber
                                        data-testid="form_input_phonenumber"
                                        label="Phone"
                                        fieldName="phoneNumber"
                                        customOnChange={(e) => setFieldValue('phoneNumber', e.target.value)}
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <DOBField fieldName={'dateOfBirth'} label="Date of birth (MM/DD/YYYY)" />
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-3 xl:mb-4">
                                      <FormikTextFieldInput
                                        {...formikProps}
                                        fieldName="address_1"
                                        label="Address line 1"
                                      />
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-3 xl:mb-4">
                                      <FormikTextFieldInput
                                        {...formikProps}
                                        fieldName="address_2"
                                        label="Address line 2"
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <FormikTextFieldInput
                                        {...formikProps}
                                        fieldName="city"
                                        label="City"
                                        required={true}
                                      />
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <Select label="Virginia" disabled data-testid="form_state_dropdown">
                                        {[]}
                                      </Select>
                                    </div>
                                    <div className="md:col-span-2 xl:mb-4">
                                      <InputTextField
                                        message={errors?.zip as string}
                                        type="text"
                                        inputMode="numeric"
                                        error={Boolean(errors.zip && touched.zip)}
                                        label="Zip"
                                        ariaLabel="Zip"
                                        required
                                        maxLength={5}
                                        value={values['zip']}
                                        onChange={(e) => {
                                          if (!isNaN(e.target.value)) {
                                            setFieldValue('zip', e.target.value);
                                          } else {
                                            setFieldValue('zip', '');
                                          }
                                        }}
                                        name="zip"
                                        onBlur={() => {
                                          setFieldTouched('zip', true);
                                        }}
                                      />
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-3">
                                      <FormikTextFieldInput
                                        {...formikProps}
                                        fieldName="patientId_MedicalCard"
                                        label="Patient ID No./Medical Marijuana Card No."
                                        required={true}
                                        limit={25}
                                      />
                                    </div>
                                    <div className="md:col-span-2 lg:col-span-3">
                                      <DOBField
                                        required={true}
                                        fieldName={'expirationDate'}
                                        label="Expiration date (MM/DD/YYYY)"
                                      />
                                    </div>
                                  </GridCols6>
                                </FormInputContainer>
                              </div>
                              {/* STEP 3 */}
                              <div className="w-full" data-testid="patient_registration_step_3">
                                <FormHeader
                                  heading="Additional notes"
                                  currentStep={3}
                                  data-testid="patient_registration_header_3"
                                  totalStep={5}
                                />
                                <TextArea name="description" rows={10} data-testid="form_input_description" />
                              </div>
                              {/* STEP 4 */}
                              <div className="w-full" data-testid="patient_registration_step_4">
                                <FormHeader
                                  heading="More info"
                                  currentStep={4}
                                  data-testid="patient_registration_header_4"
                                  totalStep={5}
                                />
                                <FormLabel
                                  text="WOULD YOU LIKE A FREE CONSULTATION WITH OUR PATIENT CARE TEAM?"
                                  required={true}
                                />
                                {errors.freeConsultation && (
                                  <ErrorContainer>
                                    <HandleError
                                      name="freeConsultation"
                                      error={`${errors.freeConsultation}`}
                                      touched={touched.freeConsultation ? true : false}
                                    />
                                  </ErrorContainer>
                                )}
                                <RadioListContainer data-testid="patient_registration_freeConsultation">
                                  <RadioListGridContainer>
                                    {radioOptionYesNo.map((item, idx) => (
                                      <RadioCheck
                                        name="freeConsultation"
                                        value={values.freeConsultation}
                                        checked={item.title === values.freeConsultation}
                                        key={idx}
                                        label={item.title}
                                        onClick={() => setFieldValue('freeConsultation', item.title)}
                                        CustomIcon={item?.icon}
                                      />
                                    ))}
                                  </RadioListGridContainer>
                                </RadioListContainer>

                                <FormLabel text="YES, I WOULD LIKE TO RECEIVE INFORMATION, COMMUNITY UPDATES, AND SPECIAL OFFERS. WE PROMISE WE WILL NOT SELL OR SHARE YOUR CONTACT INFORMATION." />
                                <CheckboxListContainer data-testid="patient_registration_allowedEmailSms">
                                  {['Email', 'Text'].map((item) => (
                                    <CheckboxListItem key={item}>
                                      <CheckboxList
                                        label={item}
                                        name={item}
                                        onChange={(event) =>
                                          handleCheckboxOnChange({
                                            name: 'allowedEmailSms',
                                            fieldValue: values.allowedEmailSms,
                                            item,
                                            setFieldValue,
                                            event,
                                          })
                                        }
                                      />
                                    </CheckboxListItem>
                                  ))}
                                </CheckboxListContainer>

                                <FormLabel text="PACKAGING PREFERENCE" required={true} />
                                {errors.packagingPreference && (
                                  <ErrorContainer>
                                    <HandleError
                                      name="packagingPreference"
                                      error={`${errors.packagingPreference}`}
                                      touched={touched.packagingPreference ? true : false}
                                    />
                                  </ErrorContainer>
                                )}
                                <RadioListContainer data-testid="patient_registration_packagingPreference">
                                  <RadioListGridContainer>
                                    {packagingPreferenceItems.map((item, idx) => (
                                      <RadioCheck
                                        name="packagingPreference"
                                        value={values.packagingPreference}
                                        checked={item?.title === values.packagingPreference}
                                        key={idx}
                                        label={item?.title}
                                        onClick={() => setFieldValue('packagingPreference', item?.title)}
                                        CustomIcon={item?.icon}
                                      />
                                    ))}
                                  </RadioListGridContainer>
                                </RadioListContainer>
                              </div>
                              {/* STEP 5 */}
                              <div className="w-full" data-testid="patient_registration_step_5">
                                <FormHeader
                                  heading="Final authorization"
                                  currentStep={5}
                                  data-testid="patient_registration_header_5"
                                  totalStep={5}
                                />
                                <FormLabel text="VIRGINIA ID" required={true} />
                                <FilePicker
                                  label="Upload File"
                                  fieldName="virginia_ID"
                                  onChange={(file) => setFieldValue('virginia_ID', file)}
                                  values={values.virginia_ID}
                                  error={touched.virginia_ID ? errors.virginia_ID : ''}
                                  formType="VA"
                                />
                                <FormLabel text="REGISTRATION CARD" required={true} />
                                <FilePicker
                                  label="Upload File"
                                  fieldName="registrationCard"
                                  onChange={(file) => setFieldValue('registrationCard', file)}
                                  values={values.registrationCard}
                                  error={touched.registrationCard ? errors.registrationCard : ''}
                                  formType="VA"
                                />
                                <FormLabel text="LETTER OF CERTIFICATION" required={true} />
                                <FilePicker
                                  label="Upload File"
                                  fieldName="letterOfCertification"
                                  onChange={(file) => setFieldValue('letterOfCertification', file)}
                                  values={values.letterOfCertification}
                                  error={touched.letterOfCertification ? errors.letterOfCertification : ''}
                                  formType="VA"
                                />
                                <FormLabel
                                  text="SIGNATURE OF THE INDIVIDUAL GIVING THIS AUTHORIZATION"
                                  required={true}
                                />
                                {errors.signatureVirginia && (
                                  <ErrorContainer>
                                    <HandleError
                                      name="signatureVirginia"
                                      error={`${errors.signatureVirginia}`}
                                      touched={touched.signatureVirginia ? true : false}
                                    />
                                  </ErrorContainer>
                                )}
                                <div className={`min-h-[290px] ${isBiggerThanMobile ? 'w-[430px]' : 'min-w-[350px]'}`}>
                                  <SignaturePad
                                    fieldName="signatureVirginia"
                                    showSignatureImg={showSignatureImg}
                                    hideSignatureImg={hideSignatureImg}
                                    width={isBiggerThanMobile ? 430 : 350}
                                    height={176}
                                  />
                                  <div className="w-full sm:mt-6 md:mt-8">
                                    <DOBField required={true} fieldName={'date'} label="Date (authorization)" />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </FormContent>
                        <FormFooter data-testid="patient_registration_footer">
                          <div className="mb-3 flex flex-col justify-between md:flex-row">
                            <div className="sm:mb-10 sm:mt-8 md:my-0">
                              <ReCaptcha fieldName="reCaptchaToken" />
                            </div>
                            <div className="my-auto">
                              <CommonButton
                                data-testid="patient_registration_submit_button"
                                size="sm"
                                button-type="primary"
                                color="green"
                                className="md: mb-3 justify-end sm:w-full md:w-auto lg:mb-0"
                                onClick={() => formikProps.submitForm()}
                                disabled={validateSteps(values)}
                              >
                                Submit
                              </CommonButton>
                            </div>
                          </div>
                          <LegalText host={props.host} />
                        </FormFooter>
                      </CenterContainer>
                    </UpperContainer>
                  </>
                );
              }}
            </Formik>
          )}
          {showSuccessBanner && <SuccessBanner {...successMessage} />}
        </RightContainer>
      </MainContainer>
    </>
  );
};

export default PatientRegistrationFormVA;
