import tw from 'tailwind-styled-components';
import HistoryIcon from 'public/icons/figma/history.svg';

export const SignatureCanvasBox = tw.div`
  h-[180px]
  w-full
  z-[99]
  border-2
  border-dashed
  border-grey-480
`;

export const SignatureCanvasClearBox = tw.div`
  mt-3
  h-6
  w-24
  flex
  flex-row
  cursor-pointer
`;

export const ResetIcon = tw(HistoryIcon)` 
  text-leaf
  mr-3
  h-6
  w-6
`;

export const SignatureCanvasSignAgainLink = tw.p`
  text-primary-100
  cursor-pointer
  underline
  text-[1.25rem]
  font-normal
`;
