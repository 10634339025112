import Image from 'next/image';
import { CommonButton } from 'components/atoms/buttons/common-buttons/button';
import { Typography } from 'components/atoms/typography';
import { BannerContainer, BannerContent, BannerSubTitle, LogoContainer } from './style';

export const FormBanner = ({
  bannerImage,
  title,
  subTitle,
  logo,
  isBiggerThanMobile,
  pdfCta,
  'data-testid': testId,
}: any) => {
  return (
    <BannerContainer
      data-testid={testId}
      style={{
        background: `url(${bannerImage?.url})`,
      }}
    >
      <LogoContainer>
        <Image
          alt="form_banner_logo"
          data-testid="form_banner_logo"
          loading="lazy"
          src={logo?.url}
          loader={(options) => options?.src}
          height={isBiggerThanMobile ? 147 : 66}
          width={isBiggerThanMobile ? 128 : 65}
        />
      </LogoContainer>
      <BannerContent>
        <Typography variant="body-large" className="mb-2 xl:mb-6" data-testid="form_banner_title">
          {title}
        </Typography>

        <BannerSubTitle>
          <Typography variant="h4" data-testid="form_banner_subtitle">
            {subTitle}
          </Typography>
        </BannerSubTitle>
        {pdfCta && (
          <div className="mt-2 flex xl:mx-auto xl:mt-[68px]">
            <CommonButton
              size="sm"
              button-type="primary"
              color="green"
              as="a"
              href={pdfCta.longUrl}
              data-testid={pdfCta.title}
              target="_blank"
            >
              {pdfCta.title}
            </CommonButton>
          </div>
        )}
      </BannerContent>
    </BannerContainer>
  );
};
