import { useEffect, useRef } from 'react';
import { CheckboxProps } from '@material-tailwind/react/components/Checkbox';
import Checkbox, { CheckboxLegalProps } from '../checkbox-legal';

const LIST_CONTAINER_STYLES = `items-center flex-row-reverse lg:flex-row`;

const CheckboxList = ({
  label = 'Label',
  defaultChecked = false,
  onChange,
  className,
  name,
  disabled,
}: CheckboxProps & CheckboxLegalProps) => {
  const ref = useRef<React.RefObject<HTMLElement>>(null);

  useEffect(() => {
    if (ref.current && ref.current.current) {
      ref.current.current.className = ref.current.current.className.replace('flex-start', LIST_CONTAINER_STYLES);
    }
  }, [disabled]);

  return (
    <Checkbox
      ref={ref}
      label={label}
      name={name}
      className={className}
      defaultChecked={defaultChecked}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export default CheckboxList;
