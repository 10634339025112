import { useState } from 'react';
import { IconWithTextButton } from 'components/atoms/buttons/icon-button/icon-with-text';
import InputTextField from 'components/atoms/inputs/text-field';
import MinusIcon from 'public/icons/figma/minus.svg';
import PlusIcon from 'public/icons/figma/plus.svg';
import { FormLabel } from './patient-reg-header';
import { Grid } from './style';

interface IProps {
  formikProps: any;
}

const CurrentMedication = ({ formikProps }: IProps) => {
  const [currentMedication, setCurrentMedication] = useState(formikProps.values.currentMedication);
  const removeMedication = (index) => {
    if (currentMedication.length !== 1) {
      currentMedication.splice(index, 1);
      formikProps.setFieldValue('currentMedication', currentMedication);
    }
  };
  const addMedication = (index) => {
    currentMedication.splice(index + 1, 0, { medicationName: '', frequency: '', dosage: '' });
    formikProps.setFieldValue('currentMedication', currentMedication);
  };
  const handelChange = (fieldName, index, value) => {
    currentMedication[index][fieldName] = value;
    setCurrentMedication(currentMedication);
    formikProps.setFieldValue('currentMedication', currentMedication);
  };
  return (
    <div className="flex flex-col">
      <FormLabel text="Current Medications? (Prescription & Nutritional Supplements)" required={false} />
      {currentMedication.map((values, idx) => (
        <div key={'medication' + idx} className="flex flex-col">
          <Grid>
            <InputTextField
              {...formikProps}
              fieldName="medicationName"
              label="Name Of Medication"
              value={values.medicationName}
              onChange={(event) => handelChange('medicationName', idx, event.target.value)}
            />

            <InputTextField
              {...formikProps}
              fieldName="dosage"
              label="Dosage"
              value={values.dosage}
              onChange={(event) => handelChange('dosage', idx, event.target.value)}
            />

            <InputTextField
              {...formikProps}
              fieldName="frequency"
              label="Frequency"
              value={values.frequency}
              onChange={(event) => handelChange('frequency', idx, event.target.value)}
            />
          </Grid>
          <div className="float-end my-4 flex flex-col justify-end gap-4 md:flex-row md:gap-3">
            <IconWithTextButton
              data-testid="current_medication_remove"
              size="sm"
              button-type="primary"
              color="green"
              icon={<MinusIcon />}
              iconPosition="after"
              onClick={() => removeMedication(idx)}
            >
              Remove
            </IconWithTextButton>
            <IconWithTextButton
              data-testid="current_medication_Add"
              size="sm"
              button-type="primary"
              color="green"
              icon={<PlusIcon />}
              iconPosition="after"
              onClick={() => addMedication(idx)}
            >
              Add
            </IconWithTextButton>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CurrentMedication;
