import { BLOCKS } from '@contentful/rich-text-types';
import { Typography } from 'components/atoms/typography';
import { ContentfulRichText } from 'components/organisms/media/rich-text-component/rich-text-react-render';

interface CheckboxLabelProps {
  label?: any;
  isSelected?: boolean;
  isInnerHtml?: boolean;
  isRichText?: boolean;
  onClick?: (e) => void;
}

const CheckboxLabel = ({
  label = '',
  isSelected = false,
  isInnerHtml = false,
  isRichText = false,
  onClick,
}: CheckboxLabelProps) => {
  const handleClick = (e) => {
    if (onClick) onClick(e);
  };
  const labelStyle = isSelected ? '!text-grey-500' : '!text-grey-400';
  return isRichText ? (
    <ContentfulRichText
      data={label}
      renderNodeOptions={{
        [BLOCKS.PARAGRAPH]: (node, children) => (
          <Typography
            variant="body-small"
            className="!text-grey-400"
            onClick={(e) => handleClick(e)}
            data-testid="typography"
          >
            {children}
          </Typography>
        ),
      }}
    />
  ) : typeof label === 'string' ? (
    <Typography variant="body-small" className={labelStyle}>
      {isInnerHtml ? <span dangerouslySetInnerHTML={{ __html: label }} /> : label}
    </Typography>
  ) : (
    <span className={labelStyle}>{label}</span>
  );
};

export default CheckboxLabel;
