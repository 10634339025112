import tw from 'tailwind-styled-components';
import { Typography } from 'components/atoms/typography';
import { ReplaceUrlWithHost } from 'helpers/utils/common';
import { DeclarationName } from './style';

interface IProps {
  heading: string;
  subHeading?: string;
  currentStep?: number;
  totalStep?: number;
}

export const HeadingContainer = tw.div`
    flex
    flex-col
    mt-6
    gap-4
    md:gap-6
`;
export const Asterisk = tw.span`
    text-resin
    ml-1
`;

export const FormHeader = ({ heading, subHeading, currentStep, totalStep = 3 }: IProps) => {
  return (
    <div className="mb-9 md:mb-14">
      {currentStep && currentStep > 0 && (
        <Typography variant="body-small" className={`mr-5 !text-grey-400 lg:mr-8`} data-testid="form_header_step">
          {`Step ` + `${currentStep} of ${totalStep}`}
        </Typography>
      )}
      <HeadingContainer>
        <Typography variant="h3" data-testid="form_header_heading">
          {heading}
        </Typography>
        {subHeading && (
          <Typography
            variant="body-small"
            className={`mr-5 !text-grey-400 lg:mr-8`}
            data-testid="form_header_subheading"
          >
            {subHeading}
          </Typography>
        )}
      </HeadingContainer>
    </div>
  );
};

interface IPropsLabel {
  text: string;
  required?: boolean;
}
export const FormLabel = ({ text, required }: IPropsLabel) => {
  return (
    <Typography variant="h5" data-testid="patient_registration_label" className="sm:my-8 md:mt-10">
      {text}
      {required && <Asterisk data-testid="patient_registration_label_required">*</Asterisk>}
    </Typography>
  );
};

export const AuthorizationDeclaration = ({ handelOnchangeName, value }: any) => {
  return (
    <div className="my-8 flex xl:my-14" data-testid="patient_reg_declaration">
      <Typography variant="body-small" data-testid="patient_auth" className="!text-grey-400">
        I
        <DeclarationName
          aria-label="declarationName"
          name="declarationName"
          placeholder="Full name"
          value={value}
          onChange={(e) => handelOnchangeName(e.target.value)}
        />
        authorize Green Thumb Industries dispensary to disclose and/or release my protected health information and/or
        purchase information as described below to:
      </Typography>
    </div>
  );
};

export const LegalText = (props: { host: string }) => {
  return (
    <Typography
      variant="body-small"
      className={` !text-grey-400 lg:text-right [&_a]:underline`}
      data-testid="form_header_subheading"
    >
      By submitting you are agreeing to our GTI Grows{' '}
      <a href={ReplaceUrlWithHost('{{url}}/terms/', props.host)}>Terms & Conditions</a> and{' '}
      <a href={ReplaceUrlWithHost('{{url}}/privacy-policy', props.host)}>Privacy Policy</a>
    </Typography>
  );
};
