import { IFormTrackEvent, SegementTrackEvent } from 'helpers/analytics/segment-helper';
import { formatPhoneNumber } from 'helpers/utils/format-phonenumber';
import { BlueshiftEvents, SegmentEvents } from '../constants/forms';

export const patientRegistrationFormSubmit = async (values) => {
  const { email, phoneNumber, state, dispensary, firstName, lastName, help_you_with, description, howDidYouFindUs } =
    values;
  try {
    const data: IFormTrackEvent = {
      lead_email: email,
      phone_number: phoneNumber ? formatPhoneNumber(phoneNumber) : phoneNumber,
      form_state_name: state,
      form_dispensary_name: dispensary,
      first_name: firstName,
      last_name: lastName,
      form_type: BlueshiftEvents.PATIENT_REGISTRATION,
      form_details: {
        user_query: help_you_with,
        discovery: howDidYouFindUs,
        user_message: description,
      },
    };
    return await SegementTrackEvent(SegmentEvents.FORM_SUBMITTED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};

export const patientRegistrationVAFormSubmit = async (values) => {
  try {
    let data: IFormTrackEvent = {};
    if (values?.registeredWithCommonwealth === 'Yes') {
      data = {
        address_1: values?.address_1,
        address_2: values?.address_2,
        city: values?.city,
        zip: values?.zip ? parseInt(values?.zip) : 0,
        birthdate: values?.dateOfBirth ? new Date(values?.dateOfBirth).toISOString() : '',
        lead_email: values?.email,
        phone_number: values?.phoneNumber ? formatPhoneNumber(values?.phoneNumber) : values?.phoneNumber,
        form_state_name: values?.state,
        form_dispensary_name: values?.dispensary,
        first_name: values?.firstName,
        middle_name: values?.middleName,
        last_name: values?.lastName,
        form_type: BlueshiftEvents.PATIENT_REGISTRATION,
        form_details: {
          discovery: values?.howDidYouFindUs,
          user_message: values?.description,
          user_attachments: values?.user_attachments,
          registered: values?.registeredWithCommonwealth === 'Yes' ? true : false,
          veteran: values?.veteran === 'Yes' ? true : false,
          patient_id_number: values?.patientId_MedicalCard,
          patient_id_expiration: values?.expirationDate,
          free_consultation: values?.freeConsultation === 'Yes' ? true : false,
          communication_preference: values?.allowedEmailSms,
          packaging_preference: values?.packagingPreference,
          authorization_date: values?.date ? new Date(values?.date).toISOString() : null,
        },
      };
      if (!data?.zip) delete data?.zip;
      if (!data?.birthdate) delete data?.birthdate;
    } else {
      data = {
        lead_email: values?.email,
        phone_number: values?.phoneNumber ? formatPhoneNumber(values?.phoneNumber) : values?.phoneNumber,
        form_state_name: values?.state,
        form_dispensary_name: values?.dispensary,
        first_name: values?.firstName,
        last_name: values?.lastName,
        form_type: BlueshiftEvents.PATIENT_REGISTRATION,
        form_details: {
          discovery: values?.howDidYouFindUs,
        },
      };
    }

    return await SegementTrackEvent(SegmentEvents.FORM_SUBMITTED, data, { sendGeoData: true });
  } catch (err) {
    console.log(err);
    throw Error(err);
  }
};

export const patientRegistrationNYFormSubmit = async (values) => {
  try {
    const data: IFormTrackEvent | any = {
      address_1: values?.address_1,
      address_2: values?.address_2,
      city: values?.city,
      zip: values?.zip ? parseInt(values?.zip) : null,
      birthdate: values?.dateOfBirth ? new Date(values?.dateOfBirth).toISOString() : null,
      lead_email: values?.email,
      phone_number: values?.phoneNumber ? formatPhoneNumber(values?.phoneNumber) : values?.phoneNumber,
      form_state_name: values?.state,
      form_dispensary_name: values?.dispensary,
      first_name: values?.firstName,
      last_name: values?.lastName,
      form_type: BlueshiftEvents.PATIENT_REGISTRATION,
      home_number: values?.homeNumber,
      occupation: values?.occupation,
      form_details: {
        user_attachments: values?.user_attachments,
        medicalcard_number: values?.medicalCardNumber,
        communication_preference: values?.allowedComunication,
        financial_hardships: values?.financialHardships === 'Yes' ? true : false,
        senior: values?.senior === 'Yes' ? true : false,
        veteran: values?.veteran === 'Yes' ? true : false,
        caregiver_name: values?.careGiverName,
        caregiver_phone: values?.careGiverNumber,
        known_allergies: values?.allergies,
        current_medications: values?.currentMedication,
        medicalcannabishistory_reason: values?.whyHasMedicalCannabisBeenRecommendedToYou,
        medicalcannabishistory_lengthofusage: values?.howLongHaveYouBeenUsingMedicalCannabis,
        medicalcannabishistory_consumptionpreference: values?.doYouHaveAPreferredMethodOfConsumption,
        medicalcannabishistory_discovery: values?.howDidYouFindUs,
        privacypracticenotice_date: values?.DatePrivacyPracticesNotice
          ? new Date(values?.DatePrivacyPracticesNotice).toISOString()
          : null,
        codeofconductagreement_date: values?.DateCodeOfConductAgreement
          ? new Date(values?.DateCodeOfConductAgreement).toISOString()
          : null,
        authorization: {
          full_name: values?.declarationName,
          name: values?.nameAuthorization,
          relationship: values?.relationshipAuthorization,
          phone: values?.phoneAuthorization,
          address: values?.addressAuthorization,
          disclosure_info: values?.informationToBeDisclosed,
          disclosure_form: values?.formOfDisclosure,
          effective_until: values?.authorizationShallBeEffectiveUntil,
          authorizer_name: values?.nameOfTheIndividualGivingThisAuthorization,
          authorizer_birthdate: values?.dateOfBirthAuthorization
            ? new Date(values?.dateOfBirthAuthorization).toISOString()
            : null,
          authorization_date: values?.dateAuthorization ? new Date(values?.dateAuthorization).toISOString() : null,
        },
      },
    };
    if (!data?.zip) delete data?.zip;
    if (!data?.birthdate) delete data?.birthdate;

    return await SegementTrackEvent(SegmentEvents.FORM_SUBMITTED, data, { sendGeoData: true });
  } catch (err) {
    throw Error(err);
  }
};
